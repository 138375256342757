import axios from 'axios'
import store from '../../events/Store'
import config from '../../events/Config'


/*export default (() =>
    axios.create({
        withCredentials: true,
        baseURL: config.ApplicationAPI,
        headers: {
            common: {
                Authorization: localStorage.getItem('token') ? ('Bearer ' + localStorage.getItem('token')) : null,
                Lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : null
            }
        }
    })
)()*/


var instance = axios.create({
    withCredentials: true,
    baseURL: config.ApplicationAPI,
    headers: {
        common: {
            Authorization: localStorage.getItem('token') ? ('Bearer ' + localStorage.getItem('token')) : null,
            Lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : null
        }
    }
})

instance.interceptors.response.use(function (response) {
    if(response.data.prill) {
        for (let i in response.data.prill) {
            $cookies.set(i, response.data.prill[i])
        }
    }
    return response
}, function (error) {
    return Promise.reject(error)
})

export default (() => instance)()